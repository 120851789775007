import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { USER_STREAK_STATS_ENDPOINT } from "@/app/_consts/internal";

interface BadgeDetail {
  current_streak: number;
  longest_streak: number;
  year_to_date: number;
}

export interface StreakStatPoints {
  huddles: number;
  reading: number;
  streaks: number;
  photos: number;
  games: number;
}

export interface StreakStatsPoints extends StreakStatPoints {
  totalPoints: number;
}

interface StreakStats {
  counts: {
    articles_read: number;
    photos_uploaded: number;
    huddles_joined: number;
    daily_articles_read: number;
    banters_won: number;
    chapters_completed: number;
    weekly_streaks: number;
    vegisms_won: number;
    vegisms_unused: number;
    banters_unused: number;
    huddles_started: number;
    manifesto_complete: boolean;
  };
  total_points: number;
  average_huddles_per_week: number;
  current_huddle_streak: number;
  longest_huddle_streak: number;
  huddles_started: number;
  badges: {
    bookworm: BadgeDetail;
    perfect_huddler: BadgeDetail;
  };
  point_totals: StreakStatPoints;
}

interface UserStreakState {
  isLoading: boolean;
  userStreakStats: StreakStats | null;
}

const initialState: UserStreakState = {
  isLoading: true,
  userStreakStats: null,
};

export const fetchUserStreakStats = createAsyncThunk<StreakStats, string>(
  "userStats/fetchUserStreakStats",
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await axios.get(USER_STREAK_STATS_ENDPOINT, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred");
      }
    }
  },
);

const userStreakStatsSlice = createSlice({
  name: "userStreakStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStreakStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserStreakStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userStreakStats = action.payload;
      })
      .addCase(fetchUserStreakStats.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default userStreakStatsSlice.reducer;
